import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';

import Nav from '../Components/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faStore } from '@fortawesome/free-solid-svg-icons';

const Home = () => {

    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);

    const loadAnalytics = async () => {
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const restaurantsRes = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/analytics/tenant',
                {
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            )
            console.log(restaurantsRes.data.data)
            setTotalRevenue(restaurantsRes.data.data.analytics.total_revenue.toFixed(2));
            setTotalOrders(restaurantsRes.data.data.analytics.total_orders)
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        loadAnalytics(); // Load user data when component mounts
    }, []);

    return (
        <div className="page-wrapper">
            <Nav />
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 page-title">
                            <h1>Dashboard</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="border-left margin-top-30">Please note the system is currently under major development. Please report any bugs you see to <a href="mailto:niko@orderwithease.ca" target="_blank" className="highlight">niko@orderwithease.ca</a>.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <div className="block-wrapper">
                                <div className="block-left bg-color-primary">
                                    <FontAwesomeIcon icon={faMoneyBill} />
                                </div>
                                <div className="block-right">
                                    <div className="col-xs-12 col-sm-12">
                                        <h2>${totalRevenue}</h2>
                                        <h3>Total Revenue</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <div className="block-wrapper">
                                <div className="block-left bg-color-primary">
                                    <FontAwesomeIcon icon={faMoneyBill} />
                                </div>
                                <div className="block-right">
                                    <div className="col-xs-12 col-sm-12">
                                        <h2>{totalOrders}</h2>
                                        <h3>Total Orders</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <div className="block-wrapper">
                                <div className="block-left bg-color-primary">
                                    <FontAwesomeIcon icon={faStore} />
                                </div>
                                <div className="block-right">
                                    <div className="col-xs-12 col-sm-12">
                                        <h2>Coming Soon</h2>
                                        <h3>Restaurants</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
