import React, { useState, useEffect } from "react";
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStore, faReceipt, faBook, faChartBar, faGear, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../../img/order-with-ease-logo.svg';
import Nav from '../../../Components/Nav';

const Restaurants = () => {
    const [restaurantsData, setRestaurantsData] = useState([]);

    useEffect(() => {
        const loadUserData = async () => {
            try {
                const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                });
                const tenantId = userRes.data.data.admin_tenant_id;
                const restaurantsRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/restaurants?id=${tenantId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                });
                setRestaurantsData(restaurantsRes.data.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        loadUserData(); // Load user data when component mounts
    }, []);

    const logout = () => {
        sessionStorage.removeItem("token");
        window.location.reload();
    };

    return (
        <div className="page-wrapper">
            <Nav />
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 page-title">
                            <h1>Restaurants</h1>
                        </div>
                    </div>
                    <div className="row margin-top-30">
                        {restaurantsData.map((restaurant, index) => (
                            <div className="col-xs-6 col-sm-6 col-md-6 col-xl-4" key={index}>
                                <a href={"https://" + restaurant.franchise_fqdn} target="_blank"><div className="block-wrapper">
                                    <div className="block-left bg-color-primary">
                                        <FontAwesomeIcon icon={faStore} />
                                    </div>
                                    <div className="block-right">
                                        <div className="col-xs-6 col-sm-6">
                                            <h2>{restaurant.restaurant_nickname}</h2>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 text-right">
                                            <h3>{restaurant.restaurant_status != null && restaurant.restaurant_status == true ? <><span className="status-bubble online"></span> Online</> : <><span className="status-bubble offline"></span> Offline</>}</h3>
                                        </div>
                                    </div>
                                </div></a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Restaurants;
