import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStore, faReceipt, faBook, faChartBar, faGear, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../../img/order-with-ease-logo.svg';
import Nav from '../../../Components/Nav';
const Settings = () => {

    const [tenantData, setTenantData] = useState([]);

    const loadUserData = () => {
        axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            }
        }).then(res => {
            let tenantId = res.data.data.admin_tenant_id
            axios.get(`https://angeliques-api.vervedev.com/api/users_admin/tenant?id=${tenantId}`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            }).then(tenantResponse => {
                setTenantData(tenantResponse.data.data[0]);
            });
        }).catch(error => {
            console.error('Error fetching user data:', error);
        });
    };

    const logout = () => {
        sessionStorage.removeItem("token");
        window.location.reload();
    }

    useEffect(() => {
        loadUserData(); // Load user data when component mounts
    }, []);

    return (
        <div className="page-wrapper">
            <Nav/>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 page-title">
                            <h1>Settings</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="border-left">{tenantData.tenant_name != null ? tenantData.tenant_name : "loading..."}</h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <hr></hr>
                    </div>
                    <div className="row margin-top">
                        <div className="col-md-3">
                            <h3>Your Logo:</h3>
                            <h4><img src={tenantData.logo_url != null ? tenantData.logo_url : null} width="150" /></h4>
                        </div>
                        <div className="col-md-3">
                            <h3>Primary Colour:</h3>
                            <h4>#{tenantData.primary_colour != null ? tenantData.primary_colour : null}</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <hr></hr>
                    </div>
                    <div className="row margin-top-30">
                    <div className="col-md-3">
                            <h3>Is Franchised:</h3>
                            <h4>{tenantData.is_franchise != null && tenantData.is_franchise == true ? "Yes" : "No"}</h4>
                        </div>
                        <div className="col-md-3">
                            <h3>Franchise Fee:</h3>
                            <h4>{tenantData.tenant_franchise_fee != null ? tenantData.tenant_franchise_fee : null}%</h4>
                        </div>
                        <div className="col-md-3">
                            <h3>OrderWithEase Fee:</h3>
                            <h4>{tenantData.tenant_application_fee != null ? tenantData.tenant_application_fee : null}%</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
