import React, { useState, useEffect } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus, faEllipsis, faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'react-bootstrap';
import Nav from '../../../Components/Nav';
import {
    Droppable,
    Draggable,
    DragDropContext
} from "@hello-pangea/dnd";

const Menu = () => {
    const [menuItems, setMenuItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [visibleCategories, setVisibleCategories] = useState([]);
    const [options, setOptions] = useState([]);
    const [choices, setChoices] = useState([]);
    const [choicesGroups, setChoicesGroups] = useState([]);

    const [visibleItems, setVisibleItems] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [activeMenuItem, setActiveMenuItem] = useState(null);
    const [visibleOptions, setVisibleOptions] = useState(null);
    const [visibleChoicesGroups, setVisibleChoicesGroups] = useState(null);
    const [visibleChoices, setVisibleChoices] = useState(null);

    const [openGroup, setOpenGroup] = useState(null);
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);

    /* Categories */
    const [showAddCategoryTooltip, setShowAddCategoryTooltip] = useState(false);
    const [addCategoryName, setAddCategoryName] = useState('');

    /* Items */
    const [showAddItemTooltip, setShowAddItemTooltip] = useState(false);
    const [addItemName, setAddItemName] = useState('');
    const [addItemDescription, setAddItemDescription] = useState('');
    const [addItemPrice, setAddItemPrice] = useState(0);

    const [showAddOptionTooltip, setShowAddOptionTooltip] = useState(false);
    const [addOptionName, setAddOptionName] = useState('');
    const [addOptionPrice, setAddOptionPrice] = useState('');

    const [addChoicesGroupName, setAddChoicesGroupName] = useState('');
    const [showAddChoicesGroupTooltip, setShowAddChoicesGroupTooltip] = useState(false);

    const [addChoiceName, setAddChoiceName] = useState('');
    const [addChoicePrice, setAddChoicePrice] = useState('');
    const [showAddChoiceTooltip, setShowAddChoiceTooltip] = useState(false);

    const [prevMenuData, setPrevMenuData] = useState([]);
    const [shouldHandleMenuItemClick, setShouldHandleMenuItemClick] = useState(false);

    /* Options Popup START */
    const [showCategoryOptions, setShowCategoryOptions] = useState(false);
    const [showItemOptions, setShowItemOptions] = useState(false);
    const [showOptionOptions, setShowOptionOptions] = useState(false);
    const [showChoicesOptions, setShowChoicesOptions] = useState(false);
    const [showChoiceOptions, setShowChoiceOptions] = useState(false);
    /* Options Popup END */

    useEffect(() => {
        loadUserData();
    }, []);

    useEffect(() => {
        if (JSON.stringify(menuItems) !== JSON.stringify(prevMenuData)) {
            handleMenuItemClick(activeMenuItem);
            setPrevMenuData(menuItems);
        }
    }, [menuItems, activeMenuItem, prevMenuData]);

    useEffect(() => {
        if (shouldHandleMenuItemClick) {
            handleMenuItemClick(activeMenuItem);
            setShouldHandleMenuItemClick(false);
        }
    }, [shouldHandleMenuItemClick, activeMenuItem]);

    const loadUserData = async (categoryId = null) => {
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const restaurantsRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/menu?id=${tenantId}`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
    
            const sortedItems = [...restaurantsRes.data.data.menuItems].sort((a, b) => {
                if (a.item_category_id !== b.item_category_id) {
                    return a.item_category_id - b.item_category_id;
                }
                return a.sort_order - b.sort_order;
            });
    
            const sortedCategories = [...restaurantsRes.data.data.categories].sort((a, b) => a.sort_order - b.sort_order);
            setCategories(sortedCategories);
            setMenuItems(sortedItems);
            setVisibleCategories(sortedCategories);
            setOptions(restaurantsRes.data.data.options);
            setChoices(restaurantsRes.data.data.choices);
            setChoicesGroups(restaurantsRes.data.data.choicesGroups);
    
            if (categoryId !== null) {
                const filteredItems = sortedItems.filter(item => item.item_category_id === categoryId);
                setVisibleItems(filteredItems);
                setActiveCategory(categoryId);
            } else if (activeCategory !== null) {
                const filteredItems = sortedItems.filter(item => item.item_category_id === activeCategory);
                setVisibleItems(filteredItems);
            } else {
                setVisibleItems(sortedItems);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };
    

    const handleCategoryClick = (categoryId) => {
        if (categoryId === null) {
            setVisibleItems(menuItems);
        } else {
            const filteredItems = menuItems.filter(item => item.item_category_id === categoryId);
            setVisibleItems(filteredItems);
        }
        setActiveCategory(categoryId);
        setActiveMenuItem(null);
    };

    const handleMenuItemClick = (itemId) => {
        setActiveMenuItem(itemId);
        const selectedItem = menuItems.find(item => item.item_id === itemId);
        if (selectedItem) {
            setPrice(selectedItem.item_price);
            setDescription(selectedItem.item_description);

            const itemOptions = options.filter(option => option.menu_item_id === itemId);
            setVisibleOptions(itemOptions);

            const itemChoicesGroups = choicesGroups.filter(group => group.menu_item_id === itemId);
            setVisibleChoicesGroups(itemChoicesGroups);

            const itemChoices = itemChoicesGroups.reduce((acc, group) => {
                const groupChoices = choices.filter(choice => choice.choice_group_id === group.id);
                return [...acc, ...groupChoices];
            }, []);
            setVisibleChoices(itemChoices);
        } else {
            setPrice(0);
            setDescription('');
        }
    };

    const updateMenuItem = (description, price, itemId) => {
        setLoading(true);
        axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            }
        }).then(res => {
            let tenantId = res.data.data.admin_tenant_id
            axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/update',
                {
                    itemId: itemId,
                    description: description,
                    price: price,
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            ).then(res => {
                const updatedMenuItems = menuItems.map(item => {
                    if (item.item_id === itemId) {
                        return {
                            ...item,
                            item_description: description,
                            item_price: price
                        };
                    }
                    return item;
                });
                setMenuItems(updatedMenuItems);
                loadUserData();
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.error('Error fetching order details:', error);
            });
        }).catch(error => {
            console.error('Error fetching user data:', error);
            setLoading(false);
        });
    };

    const createItemOption = async (name, price, itemId) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/add/option',
                {
                    option: {
                        name: name,
                        price: price
                    },
                    itemId: itemId,
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
            setShouldHandleMenuItemClick(true);
            setLoading(false);
            setAddOptionName('');
            setAddOptionPrice('');
        } catch (error) {
            setLoading(false);
            console.error('Error creating item option:', error);
        }
    };

    const deleteItem = async (itemId) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/remove/item',
                {
                    item: {
                        id: itemId
                    },
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
            try {
                setLoading(false);
                setShowChoiceOptions(false);
                setShouldHandleMenuItemClick(true);
            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            setLoading(false);
            console.error('Error deleting item option:', error);
        }
    };

    const deleteItemOption = async (optionId) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/remove/option',
                {
                    option: {
                        id: optionId
                    },
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
            try {
                setLoading(false);
                setShowOptionOptions(false);
                setShouldHandleMenuItemClick(true);
            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            setLoading(false);
            console.error('Error deleting item option:', error);
        }
    };

    const deleteItemChoicesGroup = async (groupId) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/remove/choices',
                {
                    group: {
                        id: groupId
                    },
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
            try {
                setLoading(false);
                setShowChoicesOptions(false);
                setShouldHandleMenuItemClick(true);
            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            setLoading(false);
            console.error('Error deleting item option:', error);
        }
    };

    const deleteItemChoice = async (choiceId) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/remove/choice',
                {
                    group: {
                        id: choiceId
                    },
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
            try {
                setLoading(false);
                setShowChoiceOptions(false);
                setShouldHandleMenuItemClick(true);
            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            setLoading(false);
            console.error('Error deleting item option:', error);
        }
    };

    const createCategory = async (name) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/add/category',
                {
                    category: {
                        name: name
                    },
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
            try {
                setAddCategoryName('');
                setLoading(false);
                setShowAddCategoryTooltip(false);
            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            setLoading(false);
            console.error('Error creating item option:', error);
        }
    };

    const deleteCategory = async (categoryId) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/remove/category',
                {
                    category: {
                        id: categoryId
                    },
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
            try {
                setLoading(false);
                setShowCategoryOptions(false);
            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            setLoading(false);
            console.error('Error deleting item option:', error);
        }
    };

    const createItem = async (name, price, description, categoryId) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/add/item',
                {
                    item: {
                        name: name,
                        price,
                        description,
                        categoryId
                    },
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
    
            // Reload the data and retain the selected category
            await loadUserData(categoryId);
    
            // Reset the form fields
            setAddItemName('');
            setAddItemPrice('');
            setAddItemDescription('');
    
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error adding menu item:', error);
        }
    };
    

    const createItemChoicesGroup = async (name, itemId) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/add/choices',
                {
                    group: {
                        name: name
                    },
                    itemId: itemId,
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
            try {
                setAddChoicesGroupName('');
                setLoading(false);
                setShowAddChoicesGroupTooltip(false);
                setShouldHandleMenuItemClick(true);
            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            setLoading(false);
            console.error('Error creating item option:', error);
        }
    };

    const createItemChoice = async (name, price, groupid) => {
        setLoading(true);
        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/add/choice',
                {
                    group: {
                        name: name,
                        price: price,
                        groupid: groupid
                    },
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
            try {
                setAddChoiceName('');
                setAddChoicePrice('');
                setLoading(false);
                setShowAddChoiceTooltip(false);
                setShouldHandleMenuItemClick(true);
            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            setLoading(false);
            console.error('Error creating item option:', error);
        }
    };

    const handlePriceChange = (event) => {
        setPrice(parseFloat(event.target.value));
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleToggle = (groupId) => {
        setOpenGroup(openGroup === groupId ? null : groupId);
    };

    const handleAddOptionClick = (event) => {
        event.preventDefault();
        setShowAddOptionTooltip(!showAddOptionTooltip);
    };

    const handleAddChoicesGroupClick = (event) => {
        event.preventDefault();
        setShowAddChoicesGroupTooltip(!showAddChoicesGroupTooltip);
    };

    const handleAddChoiceClick = (event) => {
        event.preventDefault();
        setShowAddChoiceTooltip(!showAddChoiceTooltip);
    };

    const handleAddItemClick = (event) => {
        event.preventDefault();
        setShowAddItemTooltip(!showAddItemTooltip);
    };

    const handleAddCategoryClick = (event) => {
        event.preventDefault();
        setShowAddCategoryTooltip(!showAddCategoryTooltip);
    };

    /* Option Options START */
    const handleCategoryOptionsClick = (event, categoryId) => {
        event.preventDefault();
        setShowCategoryOptions(prevState => prevState === categoryId ? null : categoryId);
    };

    const handleItemOptionsClick = (event, itemId) => {
        event.preventDefault();
        setShowItemOptions(prevState => prevState === itemId ? null : itemId);
    };

    const handleOptionOptionsClick = (event, optionId) => {
        event.preventDefault();
        setShowOptionOptions(prevState => prevState === optionId ? null : optionId);
    };

    const handleChoicesOptionsClick = (event, choicesId) => {
        event.preventDefault();
        setShowChoicesOptions(prevState => prevState === choicesId ? null : choicesId);
    };

    const handleChoiceOptionsClick = (event, choiceId) => {
        event.preventDefault();
        setShowChoiceOptions(prevState => prevState === choiceId ? null : choiceId);
    };

    const handleDragEndCategories = async (result) => {
        if (!result.destination) return;

        const reorderedCategories = Array.from(visibleCategories);
        const [removed] = reorderedCategories.splice(result.source.index, 1);
        reorderedCategories.splice(result.destination.index, 0, removed);
        setVisibleCategories(reorderedCategories);

        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/sort/categories',
                {
                    categories: reorderedCategories,
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
            await loadUserData();
        } catch (error) {
            setLoading(false);
            console.error('Error updating sort order of category:', error);
        }
    };

    const handleDragEndItems = async (result) => {
        if (!result.destination) return;

        const reorderedItems = JSON.parse(JSON.stringify(visibleItems));
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        setVisibleItems(reorderedItems);

        try {
            const userRes = await axios.get(`https://angeliques-api.vervedev.com/api/users_admin/byid`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            });
            const tenantId = userRes.data.data.admin_tenant_id;
            const res = await axios.post(
                'https://angeliques-api.vervedev.com/api/users_admin/menu/sort/items',
                {
                    items: reorderedItems,
                    tenantId: tenantId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                    }
                }
            );
        } catch (error) {
            setLoading(false);
            console.error('Error updating sort order of category:', error);
        }
    };

    /* Option Options END */

    return (
        <div className="page-wrapper">
            <Nav />
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 page-title">
                            <h1>Menu</h1>
                        </div>
                    </div>
                    <div className="row margin-top-30">
                        <div className="col-md-12 flex">
                            <div className="split-third">
                                <h3 className="uppercase">Categories</h3>
                                <div className="menu-block bg-color-whitesmoke overflow-scroll">
                                    <div className="padding-20 menu-view">
                                        {showAddCategoryTooltip && (
                                            <div className="list-item add-input">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="block">
                                                            <label className="uppercase">Name</label>
                                                            <input type="text" placeholder="eg. Desserts" value={addCategoryName}
                                                                onChange={(e) => setAddCategoryName(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button onClick={() => createCategory(addCategoryName)}>{loading == true ? <FontAwesomeIcon icon={faSpinner} spin /> : "Create"}</button>
                                            </div>
                                        )}
                                        <button className="strip-button add-button" onClick={handleAddCategoryClick}><FontAwesomeIcon icon={faPlus} /> Add Category</button>
                                    </div>
                                    <div className={`menu-category ${activeCategory === null ? 'active' : ''}`} onClick={() => { setActiveCategory(null); setVisibleItems(menuItems) }}>
                                        <div className="flex">
                                            <div className="col-xs-6 col-sm-6">
                                                <span>All Items</span>
                                            </div>
                                            <div className="col-xs-6 col-sm-6 text-right">
                                                <button className="options-button"><FontAwesomeIcon icon={faEllipsis} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <DragDropContext onDragEnd={handleDragEndCategories}>
                                        <Droppable droppableId="categories">
                                            {(provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {visibleCategories.map((category, index) => (
                                                        <Draggable key={category.category_id} draggableId={`category-${category.category_id}`} index={index}>
                                                            {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className={`menu-category ${activeCategory === category.category_id ? 'active' : ''}`} onClick={() => handleCategoryClick(category.category_id)}>
                                                                        <div className="flex">
                                                                            <div className="col-xs-6 col-sm-6">
                                                                                <span>{category.category_name}</span>
                                                                            </div>
                                                                            <div className="col-xs-6 col-sm-6 text-right">
                                                                                <div className="position-relative">
                                                                                    <button className="options-button" onClick={(event) => handleCategoryOptionsClick(event, category.category_id)}><FontAwesomeIcon icon={faEllipsis} /></button>
                                                                                    {showCategoryOptions === category.category_id && (
                                                                                        <div className="options-popup">
                                                                                            <button className="strip-button" onClick={() => deleteCategory(category.category_id)}><FontAwesomeIcon icon={faXmark} /> Delete</button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                            <div className="split-third">
                                <h3 className="uppercase">Items</h3>
                                <div className="menu-block bg-color-whitesmoke overflow-scroll">
                                    {activeCategory != null && (
                                        <div className="padding-20 menu-view">
                                            {showAddItemTooltip && (
                                                <div className="list-item add-input">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div className="block">
                                                                <label className="uppercase">Name</label>
                                                                <input type="text" placeholder="eg. Add Sauce" value={addItemName}
                                                                    onChange={(e) => setAddItemName(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="block">
                                                                <label className="uppercase">Price</label>
                                                                <input type="number" placeholder="eg. 132.9" value={addItemPrice}
                                                                    onChange={(e) => setAddItemPrice(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="block">
                                                                <label className="uppercase">Description</label>
                                                                <textarea type="text" placeholder="eg. Add Sauce" value={addItemDescription}
                                                                    onChange={(e) => setAddItemDescription(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button onClick={() => createItem(addItemName, addItemPrice, addItemDescription, activeCategory)}>{loading == true ? <FontAwesomeIcon icon={faSpinner} spin /> : "Create"}</button>
                                                </div>
                                            )}
                                            <button className="strip-button add-button" onClick={handleAddItemClick}><FontAwesomeIcon icon={faPlus} /> Add Item</button>
                                        </div>
                                    )}
                                    <DragDropContext onDragEnd={handleDragEndItems}>
                                        <Droppable droppableId="visibleItems">
                                            {(provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {visibleItems.map((item, index) => (
                                                        <Draggable key={item.item_id} draggableId={`item-${item.item_id}`} index={index}>
                                                            {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className={`menu-category ${activeMenuItem === item.item_id ? 'active' : ''}`} onClick={() => handleMenuItemClick(item.item_id)}>
                                                                        <div className="flex">
                                                                            <div className="col-xs-8 col-sm-8">
                                                                                <span>{item.item_name}</span>
                                                                            </div>
                                                                            <div className="col-xs-4 col-sm-4 text-right">
                                                                                <div className="position-relative">
                                                                                    <button className="options-button" onClick={(event) => handleItemOptionsClick(event, item.item_id)}><FontAwesomeIcon icon={faEllipsis} /></button>
                                                                                    {showItemOptions === item.item_id && (
                                                                                        <div className="options-popup">
                                                                                            <button className="strip-button" onClick={() => deleteItem(item.item_id)}><FontAwesomeIcon icon={faXmark} /> Delete</button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                            <div className="split-third">
                                <h3 className="uppercase">Edit Item</h3>
                                <div className="menu-block bg-color-whitesmoke overflow-scroll">
                                    <div className="menu-view padding-top-30">
                                        {activeMenuItem !== null && menuItems.find(item => item.item_id === activeMenuItem) && (
                                            <>
                                                <div className="text-center">
                                                    <h4>{menuItems.find(item => item.item_id === activeMenuItem).item_name}</h4>
                                                    <img className="margin-top-20" src={menuItems.find(item => item.item_id === activeMenuItem).item_img_url} alt="Item Image" width="200" />
                                                </div>
                                                <div className="padding-20 margin-top-20">
                                                    <form className="block">
                                                        <label>Description</label>
                                                        <textarea value={description} onChange={handleDescriptionChange}></textarea>
                                                        <label htmlFor="price">Price</label>
                                                        <input type="number" id="price" value={price} onChange={handlePriceChange} placeholder="Price" min="0" max="9999" />
                                                    </form>
                                                    <div className="options-choices">
                                                        <div className="list-wrapper margin-top-20">
                                                            <div className="list-topbar uppercase">
                                                                <span>Choices</span>
                                                            </div>
                                                            {visibleChoicesGroups.map((group) => (
                                                                <div key={group.id}>
                                                                    <div className="list-item">
                                                                        <div className="flex">
                                                                            <div className="col-xs-6 col-sm-6">
                                                                                <span className="list-item-name">{group.choices_name}</span>
                                                                            </div>
                                                                            <div className="col-xs-6 col-sm-6 text-right">
                                                                                <div className="position-relative">
                                                                                    <button className="options-button" onClick={() => handleToggle(group.id)}>
                                                                                        <FontAwesomeIcon icon={faChevronDown} />
                                                                                    </button>
                                                                                    <button className="options-button" onClick={(event) => handleChoicesOptionsClick(event, group.id)}>
                                                                                        <FontAwesomeIcon icon={faEllipsis} />
                                                                                    </button>
                                                                                    {showChoicesOptions === group.id && (
                                                                                        <div className="options-popup">
                                                                                            <button className="strip-button" onClick={() => deleteItemChoicesGroup(group.id)}><FontAwesomeIcon icon={faXmark} /> Delete</button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <Collapse in={openGroup === group.id}>
                                                                            <div className="list choices">
                                                                                {visibleChoices
                                                                                    .filter((choice) => choice.choice_group_id === group.id)
                                                                                    .map((choice) => (
                                                                                        <div className="choice-item" key={choice.choice_id}>
                                                                                            <div className="flex">
                                                                                                <div className="col-xs-8 col-sm-8">
                                                                                                    <span>{choice.choice_name} (+${choice.choice_price})</span>
                                                                                                </div>
                                                                                                <div className="col-xs-4 col-sm-4 text-right">
                                                                                                    <div className="position-relative">
                                                                                                        <button className="options-button" onClick={(event) => handleChoiceOptionsClick(event, choice.id)}>
                                                                                                            <FontAwesomeIcon icon={faEllipsis} />
                                                                                                        </button>
                                                                                                        {showChoiceOptions === choice.id && (
                                                                                                            <div className="options-popup">
                                                                                                                <button className="strip-button" onClick={() => deleteItemChoice(choice.id)}><FontAwesomeIcon icon={faXmark} /> Delete</button>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                {showAddChoiceTooltip && (
                                                                                    <div className="list-item add-input">
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <div className="block">
                                                                                                    <label className="uppercase">Name</label>
                                                                                                    <input type="text" placeholder="eg. Sizes" value={addChoiceName}
                                                                                                        onChange={(e) => setAddChoiceName(e.target.value)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <div className="block">
                                                                                                    <label className="uppercase">Price</label>
                                                                                                    <input type="number" placeholder="eg. 5.99" value={addChoicePrice}
                                                                                                        onChange={(e) => setAddChoicePrice(e.target.value)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <button onClick={() => createItemChoice(addChoiceName, addChoicePrice, group.id)}>{loading == true ? <FontAwesomeIcon icon={faSpinner} spin /> : "Create"}</button>
                                                                                    </div>
                                                                                )}
                                                                                <button className="strip-button add-button" onClick={handleAddChoiceClick}><FontAwesomeIcon icon={faPlus} /> Add Choice</button>
                                                                            </div>
                                                                        </Collapse>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {showAddChoicesGroupTooltip && (
                                                                <div className="list-item add-input">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="block">
                                                                                <label className="uppercase">Name</label>
                                                                                <input type="text" placeholder="eg. Sizes" value={addChoicesGroupName}
                                                                                    onChange={(e) => setAddChoicesGroupName(e.target.value)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button onClick={() => createItemChoicesGroup(addChoicesGroupName, activeMenuItem)}>{loading == true ? <FontAwesomeIcon icon={faSpinner} spin /> : "Create"}</button>
                                                                </div>
                                                            )}
                                                            <div className="list-item">
                                                                <a href="" className="add-button" onClick={handleAddChoicesGroupClick}><FontAwesomeIcon icon={faPlus} /> Add Choice Group</a>
                                                            </div>
                                                        </div>
                                                        <div className="list-wrapper margin-top-20">
                                                            <div className="list-topbar uppercase">
                                                                <span>Options</span>
                                                            </div>
                                                            {visibleOptions.map(option => (
                                                                <div key={option.option_id}>
                                                                    <div className="list-item">
                                                                        <div className="flex">
                                                                            <div className="col-xs-10 col-sm-10">
                                                                                <span className="list-item-name">{option.option_name} (+{option.option_price})</span>
                                                                            </div>
                                                                            <div className="col-xs-2 col-sm-2 text-right">
                                                                                <div className="position-relative">
                                                                                    <button className="options-button" onClick={(event) => handleOptionOptionsClick(event, option.option_id)}><FontAwesomeIcon icon={faEllipsis} /></button>
                                                                                    {showOptionOptions === option.option_id && (
                                                                                        <div className="options-popup">
                                                                                            <button className="strip-button" onClick={() => deleteItemOption(option.option_id)}><FontAwesomeIcon icon={faXmark} /> Delete</button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {showAddOptionTooltip && (
                                                                <div className="list-item add-input">
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            <div className="block">
                                                                                <label className="uppercase">Name</label>
                                                                                <input type="text" placeholder="eg. Add Sauce" value={addOptionName}
                                                                                    onChange={(e) => setAddOptionName(e.target.value)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="block">
                                                                                <label className="uppercase">Price</label>
                                                                                <input type="number" placeholder="eg. 132.9" value={addOptionPrice}
                                                                                    onChange={(e) => setAddOptionPrice(e.target.value)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button onClick={() => createItemOption(addOptionName, Number(addOptionPrice), activeMenuItem)}>{loading == true ? <FontAwesomeIcon icon={faSpinner} spin /> : "Create"}</button>
                                                                </div>
                                                            )}
                                                            <div className="list-item">
                                                                <button className="strip-button add-button" onClick={handleAddOptionClick}><FontAwesomeIcon icon={faPlus} /> Add Option</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="margin-top-20">
                                                        <button className="btn-primary margin-right-10" onClick={() => updateMenuItem(description, price, menuItems.find(item => item.item_id === activeMenuItem).item_id)}>{loading == true ? <FontAwesomeIcon icon={faSpinner} spin /> : "Save Changes"}</button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default Menu;
