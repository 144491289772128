import 'bootstrap/dist/css/bootstrap.min.css';
import './Css/Panel.css';
import './Css/App.css';

import Home from './Pages/Home';

import Login from './Pages/Login';
import Settings from './Pages/tenant/settings/Settings';
import Restaurants from './Pages/tenant/restaurants/Restaurants';
import Menu from './Pages/tenant/menu/Menu';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRoutes'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          {/* End User Protected Routes */}

          <Route path="/login" element={<Login />} exact />

          <Route path="*" element={<Navigate to="/" />} />


          {/* Tenant Routes*/}

          <Route exact path="/" element={
            <PrivateRoutes>
              <Home />
            </PrivateRoutes>
          }>
          </Route>
          <Route exact path="/restaurants" element={
            <PrivateRoutes>
              <Restaurants />
            </PrivateRoutes>
          }>
          </Route>
          <Route exact path="/menu" element={
            <PrivateRoutes>
              <Menu />
            </PrivateRoutes>
          }>
          </Route>
          <Route exact path="/settings" element={
            <PrivateRoutes>
              <Settings />
            </PrivateRoutes>
          }>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
