import React, { useEffect } from "react";
import { useState, useContext } from 'react';
import axios from 'axios';
import Logo from '../img/order-with-ease-logo.svg';
import LoginBG from '../img/login-bg.jpg';
import { useNavigate } from "react-router-dom";
import { AppContext } from '../utils/AppContext';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [restaurantId, setRestaurantId] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const [theme, setTheme] = useState([]);

    const { updateTenantId, updateRestaurantId } = useContext(AppContext);


    let navigate = useNavigate();

    function LoginUser(e) {
        e.preventDefault();
        axios.post("https://angeliques-api.vervedev.com/api/users_admin/login", { email, password }, { withCredentials: true }).then((response) => {
            if (response.data.message !== "success") {
                alert("error");
                return;
            } else {
                sessionStorage.setItem("token", response.data.token);
                navigate("/");
                return;
            }
        });
    }

    return (
        <>
            <div className="login-wrapper">
                <div className="left-side">
                    <div className="container">
                        <div className="login-logo-wrapper">
                            <img src={Logo} alt="logo" />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Login to Admin Area</h1>
                                <p>Manage your OrderWithEase organization here.</p>
                            </div>
                        </div>
                        <div className="row">
                            <form>
                                <div className="col-md-12">
                                    <form>
                                        <label>Email</label>
                                        <input type="email" placeholder="john@doe.com" onChange={(e) => setEmail(e.target.value)} />
                                        <label>Password</label>
                                        <input type="password" placeholder="*******" onChange={(e) => setPassword(e.target.value)} />
                                        <button type="submit" onClick={(e) => LoginUser(e)}>Login Now</button>
                                    </form>
                                    <span>Forgot your password? <a href="mailto:hello@vervedev.com">Contact Administrator</a>!</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="right-side" style={{ background: `url('` + LoginBG + `') no-repeat center center` }}>

                </div>
            </div>
        </>
    );
};


export default Login;