import { Navigate, useNavigate, Outlet } from "react-router-dom"
import axios from 'axios';

import { useState, useEffect } from 'react';

const PrivateRoutes = ({ children }) => {

const [loading, setLoading] = useState(true);

// state to store whether the user is authenticated
const [loggedIn, setLoggedIn] = useState(false);

// state to store any error encounted while running the auth check.
const [error, setError] = useState();

const navigate = useNavigate();

useEffect(()=> {
    const checkLogin = async () => {
        try {
          setLoading(true);
        if (sessionStorage.getItem("token") === "undefined"){
          setLoggedIn(false);
          setLoading(false);
          navigate("/login");
        } else {
        axios.get(`https://angeliques-api.vervedev.com/api/users/find/userid`, {headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("token")
      }})
          .then(userIdResponse => {
            if (userIdResponse.data.message === "Access Denied"){
              setLoggedIn(false);
              setLoading(false);
              sessionStorage.removeItem("token");
              navigate("/login");
            } else if (userIdResponse.data.message === "Invalid Token") {
              setLoggedIn(false);
              setLoading(false);
              sessionStorage.removeItem("token");
              navigate("/login");
            } else {
              setLoggedIn(true);
              setLoading(false);
            }
          });
        }
        }
        // catch any error with the request.
        catch (err) {
            // set user to not logged in.
            setLoggedIn(false);

            // set the error to the caught exception.
            setError(err);

            // add another redirect call if you don't want to display an error when the request fails
            // e.g.: navigate("/" , {replace: true});
        }

        // request is finished set loading to false.
        setLoading(false);
    };

    checkLogin();
}, [setLoading, setError, setLoggedIn])

// This is the view while the auth check is being carried out:
if (loading) {
    return (
        <>
            
        </>
    );
} else {

// This is the view if the auth check encounters an error:
if (error) {
    return (
        <pre>
            {JSON.stringify(error, null, '\t')}
        </pre>
    );
}

    // This is the view if the user was not authenticated
    // As the redirect is started as part of the useEffect this will
    // most likely not be displayed for any significant (or even preceivable)
    // amount of time.
    if (!loggedIn) {
      <>
      
  </>
  } else{

        return(
          {...children}
        )
  }
}
}

export default PrivateRoutes