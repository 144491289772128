import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStore, faReceipt, faBook, faChartBar, faGear, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import Logo from '../img/order-with-ease-logo.svg';

const logout = () => {
    sessionStorage.removeItem("token");
    window.location.reload();
}

const Nav = () => {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <div className="sidebar">
            <div className="logo-bar">
                <img src={Logo} alt="logo" />
            </div>
            <div className="sidebar-nav-items">
                <ul className="top-section">
                    <li className="subtitle">Main</li>
                    <li className={isActive('/')}><a href="/"><span><FontAwesomeIcon icon={faHome} /></span> Dashboard</a></li>
                    <li className={isActive('/restaurants')}><a href="/restaurants"><span><FontAwesomeIcon icon={faStore} /></span> Restaurants</a></li>
                    <li className={isActive('/menu')}><a href="/menu"><span><FontAwesomeIcon icon={faBook} /></span> Menu</a></li>
                    {/* <li className="subtitle">Billing</li>
                    <li><a href=""><span><FontAwesomeIcon icon={faReceipt} /></span> Orders</a></li>
                    <li><a href=""><span><FontAwesomeIcon icon={faChartBar} /></span> Analytics</a></li> */}
                </ul>
                <ul className="bottom-section">
                    <li className={isActive('/settings')}><a href="/settings"><span><FontAwesomeIcon icon={faGear} /></span> Settings</a></li>
                    <li><a href="" onClick={() => logout()}><span><FontAwesomeIcon icon={faPowerOff} /></span> Logout</a></li>
                </ul>
            </div>
        </div>
    );
};

export default Nav;
