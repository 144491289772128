// AppContext.js
import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const ThemeProvider = ({ children }) => {

  const [tenantId, setTenantId] = useState(() => {
    // Get the value from local storage if it exists, otherwise default to #ff0000
    return localStorage.getItem('tenantId') || null;
  });

  const [restaurantId, setRestaurantId] = useState(() => {
    // Get the value from local storage if it exists, otherwise default to #ff0000
    return localStorage.getItem('restaurantId') || null;
  });

  const updateTenantId = (id) => {
    setTenantId(id);
    localStorage.setItem('tenantId', id); // Store the value in local storage
  };

  const updateRestaurantId = (id) => {
    setRestaurantId(id);
    localStorage.setItem('restaurantId', id); // Store the value in local storage
  };

  return (
    <AppContext.Provider value={{tenantId, updateTenantId, restaurantId, updateRestaurantId }}>
      {children}
    </AppContext.Provider>
  );
};
